/* eslint-disable react-hooks/rules-of-hooks */
import {
  Fragment,
  useMemo,
  useState,
  ElementType,
  useEffect,
  MutableRefObject,
  useRef,
} from "react";
import {
  Combobox,
  ComboboxOptions,
  ComboboxOption,
  ComboboxButton,
  ComboboxProps,
} from "@headlessui/react";
import joinClassNames from "classnames";

import ArrowDownIcon from "icons/outline-arrow-down.svg?react";

import globalClasses from "styles/classes.module.scss";

import Scrollbar from "./components/scrollbar";
import Input from "./components/input";
import Option from "./components/option";

import { OptionType, BaseProps, ClassNames, Sizes } from "./duck/types";
import {
  Button as DefaultButton,
  Option as DefaultOption,
} from "./options/default";
import {
  defaultFilter,
  getIsGrouped,
  getProcessedOption,
  getScrollableParent,
} from "./duck/selectors";
import { ANCHOR } from "./duck/constants";

import classes from "./styles/classes.module.scss";

interface Props<T> extends BaseProps<T> {
  placeholder?: string;
  onFilter?(query: string, item: LabelValue<T>): boolean;
  onChange(item: OptionType<T>): void;
  classNames?: ClassNames & Partial<{ input: string }>;
}

interface SearchSelectFC {
  <T, TMultiple extends boolean | undefined, TTag extends ElementType>(
    props: Props<T> & ComboboxProps<T, TMultiple, TTag> & { multiple?: false },
  ): JSX.Element;

  <T, TMultiple extends boolean | undefined, TTag extends ElementType>(
    props: Props<T> &
      ComboboxProps<Array<T>, TMultiple, TTag> & {
        multiple: true;
      },
  ): JSX.Element;
}

const SearchSelect: SearchSelectFC = ({
  options,
  placeholder = "Search for state",
  onFilter = defaultFilter,
  renderButton: ButtonComp = DefaultButton,
  renderOption = DefaultOption,
  size = "md" as Sizes,
  isLoading = false,
  themeName = "primary",
  isBlock = false,
  className = "",
  classNames = {} as Props<unknown>["classNames"],
  disabled = false,
  value = null,
  onChange,
  ...restProps
}) => {
  const [query, setQuery] = useState("");
  const isReadOnly = value && options.length < 2;

  const renderOptions = useMemo(() => {
    const lowerQuery = query.toLowerCase();

    const reducedOptions = options.reduce((acc, item) => {
      if (getIsGrouped(item)) {
        const { options, title } = item;
        const filteredOptions = options.filter(item =>
          onFilter(lowerQuery, getProcessedOption(item)),
        );

        return filteredOptions.length
          ? acc.concat({
              title,
              options: filteredOptions,
            })
          : acc;
      }

      if (onFilter(lowerQuery, getProcessedOption(item))) {
        return acc.concat(item);
      }

      return acc;
    }, [] as OptionType<unknown>[]);

    return reducedOptions.length ? (
      reducedOptions.map(option => {
        if (getIsGrouped(option)) {
          return (
            <Fragment key={option.title}>
              <li
                className={joinClassNames(
                  classes.titleOption,
                  classNames.optionTitle,
                )}
              >
                {option.title}
              </li>
              {option.options.map(option => {
                const processed = getProcessedOption(option);

                return (
                  <Option
                    data={processed}
                    key={processed.label}
                    size={size}
                    component={ComboboxOption}
                    renderOption={renderOption}
                  />
                );
              })}
            </Fragment>
          );
        }

        const processed = getProcessedOption(option);

        return (
          <Option
            key={processed.label}
            size={size}
            component={ComboboxOption}
            data={processed}
            renderOption={renderOption}
          />
        );
      })
    ) : (
      <div className={joinClassNames(classes.emptyWrapper, classNames.empty)}>
        No options
      </div>
    );
  }, [options, query]);

  return (
    <Combobox
      {...restProps}
      as="div"
      className={joinClassNames(
        classes.themePresets,
        classes.sizePresets,
        classes.wrapper,
        className,
      )}
      data-type="search-select"
      data-block={isBlock}
      data-size={size}
      data-theme={themeName}
      value={value}
      // issue https://github.com/tailwindlabs/headlessui/issues/3534
      onChange={item => item && onChange(item)}
    >
      {({ open, value }) => {
        const triggerRef = useRef(null);

        useEffect(() => {
          setQuery("");

          triggerRef.current.tabIndex = 0;

          if (open) {
            const scrollableParent = getScrollableParent(triggerRef.current);
            scrollableParent?.classList.add(globalClasses.overflowHidden);

            return () => {
              scrollableParent?.classList.remove(globalClasses.overflowHidden);
              triggerRef.current?.focus();
            };
          }
        }, [open]);

        return (
          <>
            {open ? (
              <Input
                ref={triggerRef}
                placeholder={placeholder}
                className={classNames.input}
                onChange={setQuery}
              />
            ) : (
              <ComboboxButton
                ref={triggerRef as MutableRefObject<HTMLButtonElement>}
                disabled={disabled || isLoading || isReadOnly}
                data-readonly={isLoading || isReadOnly}
                className={joinClassNames(
                  classes.selectBlankButton,
                  classes.triggerWrapper,
                  classNames.button,
                )}
              >
                {isLoading ? (
                  <p className={classes.paragraphSkeleton} />
                ) : (
                  <>
                    <ButtonComp size={size} selected={value} />
                    {!isReadOnly && (
                      <ArrowDownIcon
                        className={classes.arrowIcon}
                        data-rotated={open}
                      />
                    )}
                  </>
                )}
              </ComboboxButton>
            )}
            <ComboboxOptions
              anchor={ANCHOR}
              className={joinClassNames(classes.searchList, classNames.list)}
              as={renderOptions.length ? Scrollbar : "div"}
            >
              {renderOptions}
            </ComboboxOptions>
          </>
        );
      }}
    </Combobox>
  );
};

export default SearchSelect;
